const NighttimeBreeze = {
    title: "Nighttime Breeze",
    year: "May 2024",
    commentary: "On the search for genuine connection in a digital age",
    lyrics: `
  {Em} {C} {G} {D}
  
  The golden hour is over
  And all regrets are gone
  I ask you to come over
  As I watch the Sun go down
  
  And all the clouds are painted pink.
  Oh, how could I get used to it
  And your embrace is something that I miss
  Come to me, please, my nighttime bliss
  
  And I can feel a hand touch mine
  Your avatar's online
  
  But that's not you
  It's something else
  And I won't fall 
  Under its spell
  
  It doesn't blink
  It doesn't breathe
  It doesn't feel
  The nighttime breeze
  
  It only does the way you please
  But it's a human whom I miss
    `,
    audioSrc: "nighttime_breeze.mp3",
    imageSrc: "/path/to/a_bitter_taste.jpg"
  };
  
  export default NighttimeBreeze;
