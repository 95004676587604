import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 16px;
`;

const CardTitle = styled.h3`
  margin: 0 0 8px;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text};
`;

const CardDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.text};
  font-size: 1.18rem;
`;

const CardLink = styled.a`
  display: inline-block;
  margin-top: 12px;
  padding: 8px 16px;
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.text};
  border-radius: 4px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
  }
`;

const TagsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.body};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  margin: 4px;
`;

const ProjectCard = ({ image, title, description, link, tags }) => (
  <Card>
    <CardImage src={image} alt={title} />
    <CardContent>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <CardLink href={link} target="_blank" rel="noopener noreferrer">
        View Project
      </CardLink>
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsContainer>
    </CardContent>
  </Card>
);

export default ProjectCard;