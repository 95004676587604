const Lineage = {
  "title": "The last of my lineage",
  "year": "April 2024",
  "commentary": "A song about dreams of motherhood",
  "lyrics": `
    {C} {Em} {Am} {F}
  
    I would buy you a pretty dress
    Would braid flowers into your hair 
    So that you can show it to friends
  
    You’d have fun with your sisters too
    And I’d love every one of you
    And my life would be in your tiny hands
  
    I’d surrender my every dream
    Just so that we can always be near
    Each other in this cruel world
  
    I would teach you the things that I know
    And I would watch my girls grow
    Into ladies, and mothers
    {Am} {F}
    And we’d still have each other
  
    {C} {Em} {Am} {F}
    One day
    But this day may never come
    Cause it takes two and I’m alone
    One day
  
    One day
    But for now it’s just a dream
    It makes me want to cry and scream
    One day
  
    One day
    I will be a mother
    Or maybe I will die alone
    Cause I already sing alone
  
    {C} {E} {Am} {F}
  
    Grandmother
    You went through hunger so that I live
    So I feel stupid I can’t seem to leave
    A heir, I am the last of my lineage
  
    And you had mom at my age
  `,
  "audioSrc": "lineage.mp3",
  "imageSrc": "/path/to/one_day.jpg"
}

 export default Lineage;
