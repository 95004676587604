const Naive = {
    title: "Naïve",
    year: "August 2018",
    commentary: "A song I wrote to thank my friend for being there for me during hard times",
    lyrics: `
  {Dm} {C} {Em} {F}
  
  You were right
  I was naive
  Avoiding a fight
  Trying not to breathe
  
  You said sincerely
  I was too kind
  Forgiving easily
  Dealing with the wild
  
  Yes, I have been naive, naive, naive
  Thank you for being here for me, for me
  Yes, I have been naive, naive, naive, naive, naive
  Don't know what I would do if you weren't there for me
  Perhaps I would still be naive, naive, naive
  Perhaps I would still be naive, naive, naive
  
  I was naive
  Closed eyes
  Walking down the street
  Believing in lies
  
  I was pure
  Had a genuine heart
  Neither secure
  Nor prepared to attack
    `,
    audioSrc: "naive.mp3",
    imageSrc: "/path/to/naive.jpg"
  };
  
  export default Naive;