const ABitterTaste = {
    title: "A Bitter Taste",
    year: "February 2024",
    commentary: "On unsaid words and the bitterness they bring",
    lyrics: `
  {D} {F#m} {G} {D}
  {G} {F#m} {Em} {D}
  {Em} {F#m} {Bm} {D}
  {Em} {F#m} {Bm}
  
  {D} {F#m} {G} {D}
  
  My silence’s been a bitter
  Sludge of coffee in your cup
  I’m not a coffee drinker
  It always messes with my heart
  
  Just the way you did
  Making my heart beat
  Abnormally
  
  I haven’t even kissed you
  I don’t know the way you taste
  I can’t admit I miss you
  And I had to leave in haste
  
  I had to leave in haste
  You’re a bitter taste
  
  My silence, it speaks volumes
  And echoes deeply in my chest
  I’ve learned that certain feelings
  Are better unexpressed
  
  Buried unexpressed
  Deep inside my chest
  
  Your silence’s been a bitter 
  Sludge of coffee in my cup
  I’m not a coffee drinker
  And I won’t let it hurt my heart
    `,
    audioSrc: "a_bitter_taste.mp3",
    imageSrc: "/path/to/a_bitter_taste.jpg"
  };
  
  export default ABitterTaste;