const MothersLovingArms = {
    title: "Mother’s Loving Arms",
    year: "March 2024",
    commentary: "A song dedicated to mothers and the special love they give us",
    lyrics: `  
  Capo 2
  
  {C} {Em} {F} {G}
  
  Mother, I am all alone
  in this world without you
  No other human
  Could ever replace you
  
  {C} {Em} {F}  
  Some people want more power
  Some people crave the stars
  And I just want to be a girl
  In mother’s loving arms
  There’s nothing in this world
  Like being in your arms
  
  Mother’s loving arms
  Mother’s loving arms
  
  {C} {Em} {F} {G}
  They tell me, find a boy to love
  in pain in joy
  They tell me, try to date
  It’s something you will enjoy
  
  {C} {Em} {F} {G} 
  {Am} {G} {F}
  
  But I don’t need this kind of love
  I know it isn’t true
  
  {Dm} {G} {C} {F}
  There is nobody in this world
  {G} {C}
  Who’d love me like you do
  There is nobody in this world
  Who loves like mothers do
  
  {C} {Em} {F} {G}
  
  Mother, I miss you so,
  I hope you are okay
  Another day has passed
  And you are far away
    `,
    audioSrc: "mothers_loving_arms.mp3",
    imageSrc: "/path/to/mothers_loving_arms.jpg"
  };
  
  export default MothersLovingArms;