import React from 'react';
import styled from 'styled-components';

const Card = styled.a`
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
  transition: transform 0.2s, box-shadow 0.2s;
  text-decoration: none;
  color: ${({ theme }) => theme.text};

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 200px;
  border: none;
`;

const CardContent = styled.div`
  padding: 16px;
`;

const CardTitle = styled.h3`
  margin: 0 0 8px;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text};
`;

const CardDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.text};
  font-size: 1.18rem;
`;

const TagsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.body};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  margin: 4px;
`;

const PerformanceCard = ({ videoId, title, description, year, tags }) => (
  <Card href={`https://www.youtube.com/watch?v=${videoId}`} target="_blank" rel="noopener noreferrer">
    <VideoFrame 
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    />
    <CardContent>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
        <Tag>{year}</Tag>
      </TagsContainer>
    </CardContent>
  </Card>
);

export default PerformanceCard;