import React, { useContext } from 'react';
import styled, { ThemeContext, createGlobalStyle } from 'styled-components';
import Typing from 'react-typing-effect';
import ParticleBackground from './ParticleBackground';

// Global style to import the fonts
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
`;

const HomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 20px; /* Add padding for mobile screens */
`;

const Content = styled.div`
  display: flex;
  flex-direction: column; /* Change to column to stack the image and text */
  align-items: center;
  gap: 20px; /* Adjust gap for better spacing */
  position: relative;
  z-index: 1;
`;

const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  text-align: center; /* Center the text */
  font-size: 2rem;
  line-height: 2.5rem;

  @media (max-width: 768px) {
    padding: 0 10px; /* Add padding for mobile screens */
    font-size: 1.5rem; /* Smaller font size for mobile screens */
  }
`;

const StaticText = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.text};
  font-family: 'Dancing Script', monospace; /* Typing machine font */

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller font size for mobile screens */
  }
`;

const TypingText = styled(Typing)`
  font-size: 2rem;
  color: ${({ theme }) => theme.text};
  display: inline;
  font-family: 'Dancing Script', monospace; /* Typing machine font */

  @media (max-width: 768px) {
    font-size: 1.3rem; /* Smaller font size for mobile screens */
  }
`;

const AboutMeText = styled.p`
  margin-top: 20px;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text};
  font-family: 'Dancing Script'; /* Handwritten font */
  max-width: 600px; /* Limit the width for better readability */
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 1.3rem; /* Smaller font size for mobile screens */
  }
`;

const InvisibleDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2; /* Ensure it is above other content */
  user-select: none;
`;

const SignatureContainer = styled.div`
  width: 100px;
  height: auto;
  margin-top: 20px;
  position: relative;
`;

const Signature = styled.img`
  width: 100%;
  height: auto;
  user-drag: none; 
  user-select: none;
`;

const Home = () => {
  const themeContext = useContext(ThemeContext);
  const particleColor = themeContext.body === '#FFF' ? '162,162,162' : '255,255,255';
  const signature = themeContext.body === '#FFF' ? '/images/signature/black_aw.png' : '/images/signature/white_aw.png';

  return (
    <>
      <GlobalStyle />
      <HomeContainer>
        <ParticleBackground particleColor={particleColor} />
        <Content>
          <ImageContainer>
            <Image src="/images/projects/photo.jpeg" alt="Your Name" />
          </ImageContainer>
          <TextContainer>
            <StaticText>I am </StaticText>
            <TypingText
              text={[
                "a software engineer",
                "a singer-songwriter",
                "a manager",
                "a guitarist",
                "a polyglot",
                "just a girl",
              ]}
              speed={100}
              eraseSpeed={50}
              typingDelay={500}
              eraseDelay={500}
              cursorRenderer={(cursor) => <span>{cursor}</span>}
            />
          </TextContainer>
          <AboutMeText>
          Hello! I am Anastasia, a professional Software Engineer with a love for technology, music, and languages. 
          Whether developing an app or playing the guitar, I approach every endeavor with dedication. I firmly believe that consistency is key to mastering new skills, and I welcome new challenges to grow both personally and professionally. Let’s connect and collaborate on something extraordinary!
          </AboutMeText>
          <InvisibleDiv />
          <SignatureContainer>
            <Signature src={signature} alt="Signature" />
          </SignatureContainer>
        </Content>
      </HomeContainer>
    </>
  );
};

export default Home;