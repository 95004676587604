import React from 'react';
import styled from 'styled-components';

const AudioPlayerWrapper = styled.div`
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.cardBg};
  color: ${({ theme }) => theme.text};
  display: center;
  justify-content: flex-start;
`;

const AudioPlayer = styled.audio`
  width: 100%;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.body};

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &::-webkit-media-controls-panel {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
`;

const AudioPlayerCard = ({ audioSrc }) => {
  return (
    <AudioPlayerWrapper>
      <AudioPlayer controls>
        <source src={require(`../../components/songs/music/${audioSrc}`)} type="audio/mpeg" />
        Your browser does not support the audio element.
      </AudioPlayer>
    </AudioPlayerWrapper>
  );
};

export default AudioPlayerCard;