const NightIn2019 = {
    title: "A Night in 2019",
    year: "April 2024",
    commentary: "A raw recount of a traumatic memory",
    lyrics: `
  {C} {E} {Am} {F}
  
  A night in 2019
  And I hate that glassy yellow gleam
  In your eyes
  I was sober
  Just like you should have been
  
  But you had too much cider
  And got a bit too handsy with me
  Have you got no f*cking empathy
  I was a child, I was a teen
  
  It hasn’t been on my mind
  But people tend to remind
  Just how mean we humans can be
  
  Next day in 2019
  And you do not remember anything
  It breaks my heart that you pretend
  you don’t remember anything
  
  And then you avoid me
  Because that’s what people do
  When the spirits in their veins
  Lead them to act out of the blue
  
  If you want me to know, reveal it
  If there’s nothing to say, conceal it
  But don’t you f*cking try 
  to play some wicked games with me
  
  You probably don’t know but
  your name’s become a meme
  A local meme
  
  To depict the guys
  Who aren’t worth your time 
  The ones that you just ignore
  Not interested in keeping in touch
  Anymore
  
  {C} {E} {Am} {F}
    `,
    audioSrc: "a_night_in_2019.mp3",
    imageSrc: "/path/to/night_in_2019.jpg"
  };
  
  export default NightIn2019;