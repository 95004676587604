const NeZhdala = {
    title: "Не ждала",
    year: "July 2021",
    commentary: "An intense song about betrayal",
    lyrics: `
  {Em} {C} {Am} {G}
  
  В объятьях твоих
  Больше не тону
  Один вздох на двоих
  Предвещает беду
  
  Речи твои
  Так жестоки, резки
  Пальцы твои
  На редкость цепки
  
  Слабой груди
  Приглушённый стон
  Ты что-то говоришь,
  Но в ушах моих звон
  
  Ты выключил мне звёзды
  Чтоб наверняка
  И в порыве злости
  Посмотрел с высока на меня
  
  {C} {Am} {G} D}
  
  А я ведь этого не ждала
  От тебя такого нежного
  Я тебя невольно обожгла
  Ненавидишь теперь пуще прежнего 
  
  А я ведь этого не ждала
  От тебя такого всегда нежного
  По привычке тебя я позвала
  Но нет тебя больше прежнего 
  
  {Em} {C} {Am} {G}
  
  Резкими вздохами
  Тонких губ
  Вздутыми венами
  Холодных рук
  
  Ты утопил меня
  В кромешной тьме
  Украл Солнце у дня
  Заточил его в тюрьме
  
  Ты до крови
  Прикусил губу
  Сжимаются брови
  На хмуром лбу
  
  Ты выключил мне звёзды
  Чтоб наверняка
  И в порыве злости
  Посмотрел с высока на меня
  
  {C} {Am} {G} {D}
  
  А я ведь этого не ждала
  От тебя такого нежного
  Я тебя невольно обожгла
  Ненавидишь теперь пуще прежнего 
  
  А я ведь этого не ждала
  От тебя такого всегда нежного
  По привычке тебя подозвала
  Но нет тебя больше прежнего 
  
  {Em} {C} {G} {D}
  Выдох-Вдох
  Выдох-вдох
  Ты застал меня врасплох
  
  Выдох-вдох
  Выдох-вдох
  Выдох. Тишина
  
  И я никогда не думала
  Что разгневать смогу я милого
  У судьбы есть чувство юмора
  Погибаю от рук любимого
  
  (А я ведь этого не ждала
  От тебя такого нежного
  Я тебя невольно обожгла
  В твоём сердце буря снежная) x2
  
  А я ведь этого не ждала
  От тебя такого всегда нежного
  По щеке моей скатилась слеза
  Не спаслась я от неизбежного
    `,
    audioSrc: "ne_zdhala.mp3",
    imageSrc: "/path/to/ne_zhdala.jpg"
  };
  
  export default NeZhdala;
