import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgress, TextField, MenuItem } from '@mui/material';
import PerformanceCard from './PerformanceCard';

const PerformingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
`;

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const PerformingTitle = styled.h1`
  color: ${({ theme }) => theme.text};
  text-align: center;
`;

const SearchSortContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 20px;
`;

const SearchField = styled(TextField)`
  flex-grow: 1;
  .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.text};
    fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.text};
    &.Mui-focused {
      color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.text};
  }
`;

const SortField = styled(TextField)`
  width: 200px;
  .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.text};
    fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.text};
    &.Mui-focused {
      color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.text};
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.text};
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  justify-content: center;

  @media (min-width: 769px) {
    justify-content: start;
    gap: 24px;
  }
`;

const parseDateString = (dateString) => {
  const [month, year] = dateString.split(' ');
  return new Date(`${month} 1, ${year}`);
};

const Performing = () => {
  const [performances, setPerformances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('');

  useEffect(() => {
    // Simulating fetching performance data
    const fetchedPerformances = [
      {
        title: 'De Ce Plâng Chitarele',
        videoId: 'dZ8qDGsRIK8',
        description: 'A live performance at the ensemble contest.',
        year: 'March 2015',
        tags: ['Live', 'Guitar', 'Acoustic']
      },
      {
        title: 'Howl\'s Moving Castle Theme',
        videoId: 'qiopwOg9vvE',
        description: 'An acoustic session.',
        year: 'January 2021',
        tags: ['Live', 'Guitar', 'Acoustic']
      },
      {
        title: 'Un Dia de Noviembre',
        videoId: 'vFu2u7lV3xM',
        description: 'An acoustic guitar performance.',
        year: 'January 2016',
        tags: ['Acoustic', 'Guitar']
      },
      {
        title: '美しい残酷な世界',
        videoId: 'HjW91FO0zYo',
        description: 'Performance of a Japanese song called "Beautiful Cruel World".',
        year: 'December 2023',
        tags: ['Japanese', 'Singing']
      },
      {
        title: 'La Via A Chasa ft. Moreno Hotz',
        videoId: '1npxVpH8Mfo',
        description: 'A song I had the honour of co-writing and performing with Moreno Hotz.',
        year: 'August 2022',
        tags: ['Original', 'Collaboration', 'Singing']
      },

      {
        title: 'ヒグチアイ - いってらっしゃい',
        videoId: 'idd6pTOKby0',
        description: 'Live performance of "Itterashai" by Ai Higuchi.',
        year: 'April 2024',
        tags: ['Live', 'Japanese', 'Singing']
      }
      // Add more performance data as needed
    ];

    setPerformances(fetchedPerformances);
    setLoading(false);
  }, []);

  const filteredPerformances = performances.filter((performance) =>
    performance.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedPerformances = filteredPerformances.sort((a, b) => {
    if (sortOption === 'name-az') {
      return a.title.localeCompare(b.title);
    } else if (sortOption === 'name-za') {
      return b.title.localeCompare(a.title);
    } else if (sortOption === 'date-newest') {
      return parseDateString(b.year) - parseDateString(a.year);
    } else if (sortOption === 'date-oldest') {
      return parseDateString(a.year) - parseDateString(b.year);
    }
    return 0;
  });

  if (loading) {
    return (
      <PerformingContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </PerformingContainer>
    );
  }

  return (
    <PerformingContainer>
      <TitleContainer>
        <PerformingTitle>My Performances</PerformingTitle>
      </TitleContainer>
      <SearchSortContainer>
        <SearchField
          label="Search Performances"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SortField
          select
          label="Sort By"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="name-az">Name (A-Z)</MenuItem>
          <MenuItem value="name-za">Name (Z-A)</MenuItem>
          <MenuItem value="date-newest">Date (Newest)</MenuItem>
          <MenuItem value="date-oldest">Date (Oldest)</MenuItem>
        </SortField>
      </SearchSortContainer>
      <CardsContainer>
        {sortedPerformances.map((performance, index) => (
          <PerformanceCard
            key={index}
            videoId={performance.videoId}
            title={performance.title}
            description={performance.description}
            year={performance.year}
            tags={performance.tags}
          />
        ))}
      </CardsContainer>
    </PerformingContainer>
  );
};

export default Performing;
