const APairOfDeeperEyes = {
    title: "A Pair of Deeper Eyes",
    year: "July 2022",
    commentary: "A song about the feeling of romantic love",
    lyrics: `
  {C} {Em} {F} {C}
  
  That silver haze 
  Has covered you from head to toe
  A silent gaze
  That tells me everything 
  and even more
  
  Don’t mean to stare
  It’s just… I’ve never seen a pair
  of deeper eyes
  Kind and sincere, they empathized
  With my soul
  
  {F} {Am}
  
  Couldn’t take my eyes off you
  Even if I tried
  There isn’t anything I can do
  To train my heart to be calm inside
  
  Oooh… Oo-ooh…
  Look into my eyes
  Oooh… Oo-ooh…
  Look into my eyes
  
  {C} {Em} {F} {C}
  
  You are the first and the last 
  And I myself am aghast
  Only a goddess of the past
  Could have created you
  
  She made some fabric of the brightest stars
  Stitched it together and - surprise!
  You were so easy on the eyes
  She fell in love with you
  
  {F} {Am}
  
  Couldn’t take my eyes off you
  Even if I tried
  There isn’t anything I can do
  To keep the butterflies inside
  
  {C} {Em} {F} {C}
  
  The sky is beautiful tonight
  Look at the stars, they shine so bright
  Feels like I’d known you all my life
  Before our eyes first met
  
  And now you’re far away from me
  The Moon will keep me company
  I’m sure she was the deity
  Who made your silhouette
  
  She’s never made such a beautiful soul
  But none is perfect as a whole
  I fell in love with all your flaws
  Because they make you you
  
  {F} {Am}
  
  Couldn’t take my eyes off you
  Even if I tried
  There isn’t anything I can do
  To train my heart to be calm inside
  
  And maybe it could be
  Differently
  My feelings could have been
  A symphony
  If you think that we could be
  One, then tell me
  I just want you to be happy
  With or without me
  
  Couldn’t take my eyes off you
  Even if I tried
  There isn’t anything I can do
  To train my heart to be calm inside
  
  If you think that we could be
  One, then tell me
  I just want you to be happy
  With or without me
    `,
    audioSrc: "a_pair_of_deeper_eyes.mp3",
    imageSrc: "/path/to/a_pair_of_deeper_eyes.jpg"
  };
  
  export default APairOfDeeperEyes;