const KillingButterflies = {
    title: "Killing Butterflies",
    year: "July 2019",
    commentary: "A song about having to let go of your feelings for someone who doesn't feel the same",
    lyrics: `
    {C}
    It seemed to start so well
    {Em}
    My life, it used to be a living hell
    {Bm}                {C}
    Until your eyes met mine
  
    {C}
    You showed me something that I can’t explain
    {Em}
    You’re in my soul, my heart, you’re in my brain
    {Bm}          {C}
    Your melody’s divine
  
    {C} {Em}
    I, I, I cannot deny
    I, I hoped that we would fly
  
    {C}         {Am}         {Em}
    Who knew that I’d be killing butterflies?
    Underneath these endless winter skies
    I can’t escape your name
  
    {C}         {Am}          {Em}
    It seemed I found a place where I belong
    Until you told me that I got it wrong
    Making me fade to grey
  
    {C}
    And here we go again
    {Em}
    The same old path, old town, the same old pain
    {Bm} {C}
    You turn your head away
  
    {C}
    And I’ve been looking for a way to stay
    {Em}
    But I’m no longer here, I’m not afraid
  
    {Bm} {C}
    To start another day
  
    {C} {Em} {C} {Em}
    I, I, I cannot deny
    I, I hoped that we would fly
  
    {C} {Am} {Em} {Bm}
    Who knew that I’d be killing butterflies?
    Underneath these endless winter skies
    I can’t escape your name
  
    {C} {Am} {Em} {Bm}
    And I’ve been looking for a way to stay
    But I’m no longer here for you, I pray
    To start another day
    `,
    audioSrc: "killing_butterflies.mp3",
    imageSrc: "/path/to/killing_butterflies.jpg"
  };
  
  export default KillingButterflies;