const LaViaAChasa = {
    title: "La Via A Chasa ft. Moreno Hotz",
    year: "August 2022",
    commentary: "A multilingual exploration of nostalgia and longing for home",
    lyrics: `
  Id'es ün viadi in regordanza
  a quel mumaint.
  Jau sun sün vias, cha jau cugnuosch bain.
  Vias a mai cuntschaint.
  
  Jau'ma regord a quella frasa
  scrit sün ün banc da legn.
  E pür hoz co creschü, fa quella frasa ün segn.
  
  La giassa es stipa
  at ferma ün mumaint
  la prescha dal muond
  nu't renda cuntain
  
  Сердце горечью обливается 
  Не хотела я дом покидать
  
  Дверь одна за мной закрывается
  Чтобы путь свой могла я начать
  
  Но домой душа моя просится
  По знакомой тропинке иду
  
  Эхо прошлого следом волочится,
  Распевает слова на ветру
  
  14.08.2022
    `,
    audioSrc: "la_via_a_chasa.mp3",
    imageSrc: "/path/to/la_via_a_chasa.jpg"
  };
  
  export default LaViaAChasa;