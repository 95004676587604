export const lightTheme = {
    body: '#FFF',
    text: '#000',
    headerBg: '#f8f9fa',
  };
  
  export const darkTheme = {
    body: '#363537',
    text: '#FAFAFA',
    headerBg: '#20232a',
  };