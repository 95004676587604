import React from 'react';
import styled from 'styled-components';

const SongContainer = styled.div`
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const SongLyrics = styled.pre`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.text};
  font-family: monospace;
`;

const Chord = styled.span`
  font-weight: bold;
`;

const CHORDS = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

const transposeChord = (chord, semitones) => {
  const chordMatch = chord.match(/^([A-G][#b]?)(.*)$/);
  if (!chordMatch) return chord; // Return original chord if not matched

  const baseChord = chordMatch[1];
  const suffix = chordMatch[2];
  const index = CHORDS.indexOf(baseChord);
  if (index === -1) return chord; // Return original chord if not found

  let newIndex = (index + semitones) % CHORDS.length;
  if (newIndex < 0) newIndex += CHORDS.length;

  return CHORDS[newIndex] + suffix;
};

const renderLyrics = (lyrics, transpose) => {
  return lyrics.split('\n').map((line, index) => (
    <div key={index}>
      {line.split(/({.*?})/g).map((part, i) => {
        if (part.startsWith('{') && part.endsWith('}')) {
          const chord = part.slice(1, -1);
          const transposedChord = transposeChord(chord, transpose);
          return <Chord key={i}>{transposedChord}</Chord>;
        } else {
          return part;
        }
      })}
    </div>
  ));
};

const Song = ({ title, lyrics, transpose }) => {
  return (
    <SongContainer>
      <SongLyrics>{renderLyrics(lyrics, transpose)}</SongLyrics>
    </SongContainer>
  );
};

export default Song;