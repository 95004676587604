import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Card = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: ${({ theme }) => theme.cardBg};
  color: ${({ theme }) => theme.text};
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: ${({ showImage }) => (showImage ? 'block' : 'none')};
`;

const CardContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h3`
  margin: 0 0 8px;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text};
`;

const CardDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.text};
  font-size: 1.18rem;
`;

const TagsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const Tag = styled.span`
  background-color: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.body};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  margin: 4px;
`;

const SongCard = ({ image, title, description, year, showImage = true, link }) => (
  <Link to={link} style={{ textDecoration: 'none' }}>
    <Card>
      <CardImage src={image || '/path_to_your_default_image.jpg'} alt={title} showImage={showImage} />
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description || 'No description available'}</CardDescription>
        {year && (
          <TagsContainer>
            <Tag>{year}</Tag>
          </TagsContainer>
        )}
      </CardContent>
    </Card>
  </Link>
);

export default SongCard;