import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaMoon, FaSun } from 'react-icons/fa';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.headerBg};
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
  position: sticky;
  top: 0;
  z-index: 1000; /* High z-index to ensure it's above other elements */
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  flex: 1; /* Allow this section to take up available space */

  @media (max-width: 768px) {
    justify-content: space-around; /* Ensure links are evenly spaced */
    flex-wrap: nowrap; /* Prevent wrapping */
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  position: relative;
  transition: color 0.3s ease;
  border-radius: 8px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -3px;
    left: 0;
    background-color: ${({ theme }) => theme.text};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover {
    color: ${({ theme }) => theme.text};
  }

  &:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }

  @media (max-width: 768px) {
    margin: 0 0.5rem; /* Reduce margin on smaller screens */
  }
`;

const ThemeSwitch = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    margin-left: 0.5rem; /* Ensure there's a bit of space to the left */
  }
`;

const Dropdown = styled.div`
  border-radius: 8px;
  &:hover > div {
    display: block;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${({ theme }) => theme.headerBg};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
  border-radius: 8px;

  a {
    padding: 12px 16px;
    display: block;
    white-space: nowrap;
    border-radius: 8px;

    &:hover {
      background-color: ${({ theme }) => theme.hoverBg};
    }
  }
`;

const Header = ({ toggleTheme, theme }) => {
  return (
    <Nav>
      <NavLinks>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/projects">Projects</StyledLink>
        <Dropdown>
          <StyledLink to="#">Hobbies</StyledLink>
          <DropdownContent>
            <StyledLink to="photos">Photography</StyledLink>
            <StyledLink to="music">Songwriting</StyledLink>
            <StyledLink to="performing">Performing</StyledLink>
          </DropdownContent>
        </Dropdown>
      </NavLinks>
      <ThemeSwitch onClick={toggleTheme}>
        {theme === 'light' ? <FaMoon /> : <FaSun />}
      </ThemeSwitch>
    </Nav>
  );
};

export default Header;