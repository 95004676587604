import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Song from './Song';
import TransposeCard from './TransposeCard';
import AudioPlayerCard from './AudioPlayerCard';

const SongPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: ${({ theme }) => theme.text};
`;

const SongDetailsContainer = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

const SongContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  width: 100%;
`;

const SongDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SongTitle = styled.h1`
  margin: 0;
  font-size: 2rem;
`;

const SongYear = styled.p`
  margin: 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.textSecondary};
`;

const SongDescription = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.textSecondary};
`;

const SongPage = () => {
  const { songId } = useParams();
  const [songs, setSongs] = useState({});
  const [loading, setLoading] = useState(true);
  const [transpose, setTranspose] = useState(0);

  useEffect(() => {
    const importAll = (r) => {
      let songs = {};
      r.keys().forEach((key) => {
        const songName = key.replace('./', '').replace('.js', '');
        songs[songName] = r(key).default;
      });
      return songs;
    };

    const fetchedSongs = importAll(require.context('./songData', false, /\.js$/));
    setSongs(fetchedSongs);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const song = songs[songId];

  return (
    <SongPageContainer>
      {song ? (
        <>
          <SongDetailsContainer>
            <SongDetails>
              <SongTitle>{song.title}</SongTitle>
              <SongYear>{song.year}</SongYear>
              <SongDescription>{song.commentary}</SongDescription>
            </SongDetails>
          </SongDetailsContainer>
          <SongContent>
           <AudioPlayerCard audioSrc={song.audioSrc} />
            <Song {...song} transpose={transpose} />
            <TransposeCard transpose={transpose} setTranspose={setTranspose} />
          </SongContent>
        </>
      ) : (
        <div>Song not found</div>
      )}
    </SongPageContainer>
  );
};

export default SongPage;