const Star = {
    title: "Star",
    year: "December 2017",
    commentary: "This song is about the desire to guide people towards brighter futures while staying true to yourself and your values",
    lyrics: `
    {Am} {G} {C} {F}
    
    When I die I want to become a star
    So I could shine high in the sky
    I would guide those who are lost
    That is what I wish the most
    
    Please just let me be alone
    All my feelings, now they're gone
    I don't want you in my life
    
    Boy, just stay away from me
    'cause we aren't meant to be 
    I don't need you in my life
    
    You say that I am perfect for you, 
    But you're not perfect for me
    You see
    So let me go
    Just leave me be
    
    You think I could be your star
    But I belong to no one
    I mean it
    Just let me go
    Just leave me be
    
    One day, I will burn out
    Life is short, I have no doubt
    But I will pleased to know that I
    Did something more than just pass by
    `,
    audioSrc: "star.mp3",
    imageSrc: "/path/to/gates_of_gold.jpg"
  };
  
  export default Star;
