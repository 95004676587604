const Pure = {
    title: "Pure",
    year: "November 2020",
    commentary: "A song about personal boundaries being crossed and the struggle to go back to feeling okay",
    lyrics: `
  {C}             {G}
  You said I was beautiful
  You held me close enough
  To make me hear your beating heart
  
  {Dm}            {C}
  Surprised, I quickly pulled away
  I was polite, you told me ‘stay’
  I said ‘it didn’t feel right’
  
  {C}             {G}
  I went to sleep all alone
  Your hands on my skin
  Made me feel so stained within
  
  {Dm}            {C}
  It was your breath, it was your touch
  I didn’t want, now it’s too much
  Forgive me, Lord, if I have sinned
  
  {C}
  So how can I wash it all
  {Am}
  And wipe out the dirt of you
  {G}
  ‘Cause I have been there before
  {F}
  So how can I still be pure?
  
  {C}             {G}
  You said I was so unique
  You leaned in close to me
  Tried to kiss me on my cheek
  
  {Dm}            {C}
  I ran away, I almost cried
  I saw a friend, I said ‘I’m fine’
  But a part of me died
  
  {C}             {G}
  I went to sleep all alone
  Your lips on my skin
  Made me feel so stained within
  
  {Dm}            {C}
  I might be born a century late
  God is ashamed of this mistake
  Forgive me, Lord, for I have sinned
  
  {C}
  Somehow I am sure you’re fine
  {Am}
  You gave all the guilt to me
  {G}
  Don’t you try to cross the line
  {F}
  Because I’m born to be free
    `,
    audioSrc: "pure.mp3",
    imageSrc: "/path/to/pure.jpg"
  };
  
  export default Pure;