const Hell = {
    title: "Villain Stories: The Road to Hell is Paved with Good",
    year: "June 2024",
    commentary: "On unintentionally hurting people that you care about",
    lyrics: `
    {C} {E} {F} {C}
      
    I wish I could be an angel
    So that your skin
    Would never crease because of me
  
    Not turn you into a stranger
    Not be so flawed within
    And hurt you casually
  
    {Dm} {C} {E} {F}
    {Dm} {C} {E} {Am}
  
    I say
    «The road to hell is paved with good»
    While feeling so misunderstood
    But I know deep inside my heart
    That is how villain stories start
  
    {C} {E} {F} {C}
  
    I wish it were just a disease
    So I could get prescribed a cure 
    with ease
  
    But there’s no medicine to date
    To free me from the grip 
    of hate
  
    {Dm} {C} {E} {F}
  
    I see
    Your wings spread out as you pass by
    And soar into the bright blue sky
    Your feathers shine as clear as day
    Your beauty takes my breath away
  
    {Dm} {C} {E} {F}
  
    An angel in disguise
    Right before my eyes
    Teach me how to live like you 
    Teach me how to be like you
  
    {Dm} {C} {E} {F}
    {Dm} {C} {E} {Am}
  
    And I would trade my art
    And I would sell my heart
    Just so that wicked part of me
    Could fade away and set me free
    `,
    audioSrc: "hell.mp3",
    imageSrc: "/path/to/a_bitter_taste.jpg"
  };
  
  export default Hell;
