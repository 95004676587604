const TheOneIUsedToBe = {
    title: "The One I Used to Be",
    year: "March 2019",
    commentary: "A song on the feeling of loss and the acceptance of change we go through in life",
    lyrics: `
  {G}  {Em}  {Bm}  {C}
  
  The one I used to be
  Is nowhere to be found
  Maybe she’s asleep
  Deep underground
  
  The one I used to be
  Would never make it here
  She’d never felt complete
  She’d often shed a tear
  
  {C}            {Em}
  
  She is lost to the world,
  Due to failures, to hate
  Lost to sunshine, to cold,
  She accepted her fate
  
  Didn’t leave me a note
  Simply took a French leave
  But I wish that she wrote
  She would still visit me
  
  {C}  {Em}  {Bm}  {C}
  
  I can’t turn back the pages,
  Be the one I used to be
  But I still have me,
  Yes, I still have me
  
  I can’t go back in time and
  Be the one I used to be
  But I still have me,
  Yes, I still have me
  
  {G}  {Em}  {Bm}  {C}
  
  The one I used to be
  Isn’t coming back
  She meant so much to me
  But I lost her track
  
  The one I used to be
  Goes by a different name
  So easily deceived
  She’d never win this game
  
  {C}  {Em}  {D}  {Am}
    `,
    audioSrc: "the_one_I_used_to_be.mp3",
    imageSrc: "/path/to/the_one_I_used_to_be.jpg"
  };
  
  export default TheOneIUsedToBe;
