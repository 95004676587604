const ResurrectMe = {
    title: "Resurrect Me",
    year: "November 2019",
    commentary: "About finding the strength to heal and move on in this challenging world",
    lyrics: `
  {Em}     {Bm}     {C}     {G}
  {Am}     {Em}     {Bm}     {Am}
  
  In this world of sadness and pain
  There is no one, no one to blame
  
  Take my heart out of my chest
  But do not take all the rest
  
  Healing is longer than I expected
  Hold out your hand and resurrect me
  
  If you have always been dead
  Would you prefer life instead?
  If you have always been dead
  Would you prefer life instead?
  
  If the rain brings the rainbow
  What to expect when there’s no clouds at all
  
  Cause I could be sensitive and mild
  But I have never ever been blind

    `,
    audioSrc: "resurrect_me.mp3",
    imageSrc: "/path/to/resurrect_me.jpg"
};

export default ResurrectMe;