import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgress, TextField, MenuItem } from '@mui/material';
import SongCard from './songs/SongCard';

const MusicContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
`;

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const MusicTitle = styled.h1`
  color: ${({ theme }) => theme.text};
  text-align: center;
`;

const SearchSortContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 20px;
`;

const SearchField = styled(TextField)`
  flex-grow: 1;
  .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.text};
    fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.text};
    &.Mui-focused {
      color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.text};
  }
`;

const SortField = styled(TextField)`
  width: 200px;
  .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.text};
    fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.text};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.text};
    &.Mui-focused {
      color: ${({ theme }) => theme.text};
    }
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.text};
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.text};
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  justify-content: center;

  @media (min-width: 769px) {
    justify-content: start;
    gap: 24px;
  }
`;

const parseDateString = (dateString) => {
  const [month, year] = dateString.split(' ');
  return new Date(`${month} 1, ${year}`);
};

const Music = () => {
  const [songs, setSongs] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showImage] = useState(false);
  const [sortOption, setSortOption] = useState('');

  useEffect(() => {
    const importAll = (r) => {
      let songs = {};
      r.keys().forEach((key) => {
        const songName = key.replace('./', '').replace('.js', '');
        songs[songName] = r(key).default;
      });
      return songs;
    };

    const fetchedSongs = importAll(require.context('./songs/songData', false, /\.js$/));
    setSongs(fetchedSongs);
    setLoading(false);
  }, []);

  const filteredSongs = Object.keys(songs).filter((songKey) =>
    songs[songKey].title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedSongs = filteredSongs.sort((a, b) => {
    if (sortOption === 'name-az') {
      return songs[a].title.localeCompare(songs[b].title);
    } else if (sortOption === 'name-za') {
      return songs[b].title.localeCompare(songs[a].title);
    } else if (sortOption === 'date-newest') {
      return parseDateString(songs[b].year) - parseDateString(songs[a].year);
    } else if (sortOption === 'date-oldest') {
      return parseDateString(songs[a].year) - parseDateString(songs[b].year);
    }
    return 0;
  });

  if (loading) {
    return (
      <MusicContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </MusicContainer>
    );
  }

  return (
    <MusicContainer>
      <TitleContainer>
        <MusicTitle>My Songs & Demo Recordings</MusicTitle>
      </TitleContainer>
      <SearchSortContainer>
        <SearchField
          label="Search Songs"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SortField
          select
          label="Sort By"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="name-az">Name (A-Z)</MenuItem>
          <MenuItem value="name-za">Name (Z-A)</MenuItem>
          <MenuItem value="date-newest">Date (Newest)</MenuItem>
          <MenuItem value="date-oldest">Date (Oldest)</MenuItem>
        </SortField>
      </SearchSortContainer>
      <CardsContainer>
        {sortedSongs.map((songKey) => (
          <SongCard
            key={songKey}
            image={songs[songKey].image}
            title={songs[songKey].title}
            description={songs[songKey].commentary}
            year={songs[songKey].year}
            link={`/music/${songKey}`}
            showImage={showImage}
          />
        ))}
      </CardsContainer>
    </MusicContainer>
  );
};

export default Music;