import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center; // Center the content horizontally
  align-items: center; // Center the content vertically
  padding: 1rem;
  background-color: ${({ theme }) => theme.footerBg};
  color: ${({ theme }) => theme.text};
  width: 100%;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
`;

const Footer = () => {
  return (
    <FooterContainer>
      <div>
        &copy; {new Date().getFullYear()} Anastasia Wagner (Ivanova).
      </div>
    </FooterContainer>
  );
};

export default Footer;