import React from 'react';
import styled from 'styled-components';
import ProjectCard from './ProjectCard';

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
`;

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 20px; // Ensure there's space between the title and the project cards
`;

const ProjectsTitle = styled.h1`
  color: ${({ theme }) => theme.text};
  text-align: center;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  justify-content: center; // Center the cards on smaller screens

  @media (min-width: 769px) {
    justify-content: start; // Align the cards to the start on larger screens
    gap: 24px; // Larger gap on larger screens
  }
`;

const ProjectWrapper = styled.div`
  flex: 1 1 300px;
  max-width: 300px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    justify-self: center; // Center the card on smaller screens
  }
`;

const Projects = () => {
  return (
    <ProjectsContainer>
      <TitleContainer>
        <ProjectsTitle>My software projects</ProjectsTitle>
      </TitleContainer>
      <CardsContainer>
        <ProjectWrapper>
          <ProjectCard
            image="/images/projects/german_upd.png"
            title="Website to learn German"
            description="A React project designed to help with learning German adjective declension."
            link="https://runasharp.github.io/german"
            tags={['JavaScript', 'React', 'CSS']}
          />
        </ProjectWrapper>

        <ProjectWrapper>
          <ProjectCard
            image="/images/projects/booking.png"
            title="Telegram Bot that keeps track of Booking.com prices"
            description="A project to help with learning German adjective declension."
            link="https://github.com/runasharp/telegram-hotel-bot"
            tags={['Python', 'Telegram API', 'bot']}
          />
        </ProjectWrapper>

        <ProjectWrapper>
          <ProjectCard
            image="/images/projects/ar_draw.png"
            title="AR rock-paper-scissors game"
            description="A simple AR game made with Unity and Vuforia. Users can use cards to play rock-paper-scissors."
            link="https://github.com/runasharp/AR-Paper-Scissors-Rock"
            tags={['C#','Unity', 'AR', 'Vuforia']}
          />
        </ProjectWrapper>

        <ProjectWrapper>
          <ProjectCard
            image="/images/projects/lr.png"
            title="Linear regression model to predict house prices"
            description="An example of linear regression in Python"
            link="https://github.com/runasharp/lr-deployed"
            tags={['Python', 'Linear regression', 'Scikit-learn', 'Flask']}
          />
        </ProjectWrapper>

        <ProjectWrapper>
          <ProjectCard
            image="/images/projects/kanji.png"
            title="Japanese learning platform"
            description="A MERN stack WIP project to study Japanese. Currently such features as registration, kanji quiz and progress tab are implemented. Backend and frontend are deployed on Heroku."
            link="https://jp-frontend-188ec8849c92.herokuapp.com/visualprogress"
            tags={['React', 'Node.js', 'Express', 'MongoDB', 'Heroku']}
          />
        </ProjectWrapper>

        <ProjectWrapper>
          <ProjectCard
            image="/images/projects/plant-quiz.png"
            title="Plant Quiz App"
            description="A React app to quiz users on the Latin names of various plants. Created to help a friend studying botany prepare for exams. Uses Wikimedia API to fetch plant images."
            link="https://runasharp.github.io/plant-quiz"
            tags={['React', 'Wikimedia API', 'GitHub Pages']}
          />
        </ProjectWrapper>

        {/* Add more ProjectWrapper components here */}
      </CardsContainer>
    </ProjectsContainer>
  );
};

export default Projects;