import React from 'react';
import styled from 'styled-components';

const TransposeCardContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBg};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
`;

const TransposeButton = styled.button`
  background: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.body};
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }

  &:disabled {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.textSecondary};
    cursor: not-allowed;
  }
`;

const TransposeIndicator = styled.div`
  margin: 10px 0;
  font-weight: bold;
`;

const TransposeCard = ({ transpose, setTranspose }) => {
  const handleTranspose = (semitones) => {
    setTranspose((prevTranspose) => {
      const newTranspose = prevTranspose + semitones;
      if (newTranspose > 11) return 11;
      if (newTranspose < -11) return -11;
      return newTranspose;
    });
  };

  const revertToOriginalKey = () => {
    setTranspose(0);
  };

  return (
    <TransposeCardContainer>
      <TransposeIndicator>Transpose: {transpose > 0 ? `+${transpose}` : transpose}</TransposeIndicator>
      <div>
        <TransposeButton onClick={() => handleTranspose(-1)} disabled={transpose <= -11}>
          Transpose -1
        </TransposeButton>
        <TransposeButton onClick={() => handleTranspose(1)} disabled={transpose >= 11}>
          Transpose +1
        </TransposeButton>
      </div>
      <TransposeButton onClick={revertToOriginalKey}>
        Revert to Original Key
      </TransposeButton>
    </TransposeCardContainer>
  );
};

export default TransposeCard;