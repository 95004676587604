import React, { useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import { lightTheme, darkTheme } from './theme';

import styled from 'styled-components';  // Ensure styled-components is imported

import Header from './components/Header';
import Home from './components/Home';
import Music from './components/Music';
import Projects from './components/Projects';
import SongPage from './components/songs/SongPage';
import Photos from './components/Photos';
import Footer from './components/Footer';
import Performing from './components/Performing';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: 'Dancing Script'; /* Apply Dancing Script to all text */
    font-size: calc(115%); /* Increase font size by 6px */
    width: 100%;
    overflow-x: hidden;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const App = () => {
  const [theme, setTheme] = useState('dark'); // Default theme set to dark

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Helmet>
        <title>Anastasia Wagner</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Router>
        <AppContainer>
          <Header toggleTheme={toggleTheme} theme={theme} />
          <Content>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/music" element={<Music />} />
              <Route path="/music/:songId" element={<SongPage />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/photos" element={<Photos />} />
              <Route path="/performing" element={<Performing />} />
            </Routes>
          </Content>
          <Footer />
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
};

export default App;