import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import './Masonry.css';

const PhotosContainer = styled.div`
  padding: 20px;
`;

const PhotosTitle = styled.h1`
  color: ${({ theme }) => theme.text};
`;

const PhotoWrapper = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
`;

const Photo = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const Photos = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const importAll = (r) => {
      return r.keys().map(r);
    };

    const fetchedPhotos = importAll(require.context('../../public/images/photos', false, /\.(png|jpe?g|svg)$/));
    setPhotos(fetchedPhotos);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PhotosContainer>
      <PhotosTitle>My photos</PhotosTitle>
        <br />
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {photos.map((photo, index) => (
          <PhotoWrapper key={index}>
            <Photo src={photo} alt={`Photo ${index + 1}`} />
          </PhotoWrapper>
        ))}
      </Masonry>
    </PhotosContainer>
  );
};

export default Photos;